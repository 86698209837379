<template>
    <div>
        <div v-if="field.optionHere" class="flex ml-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none" class="optionCircle">
               <circle cx="7.0332" cy="7" r="7" fill="#0D69D5"/>
               <path d="M4.0332 7L10.0332 7" stroke="white" stroke-width="2" stroke-linecap="round"/>
            </svg>
            <p class="optionHeading ml-3">{{field.optionLabel}}</p>
        </div>
        <div v-else class="flex items-center gap-3 w-full" :class="{'ChangeCursor':this.$route.params.action == 'view'}">
        <font-awesome-icon v-if="showVerticalGrip" icon="grip-vertical" class="text-gray-500" />
        <div class="check-field bg-white transition-all duration-200 px-2 py-1 flex justify-between items-center gap-3 rounded-xl cursor-pointer flex-grow">
            <div class="flex gap-3 items-center text-sm">
                <input v-if="edited" :ref="`input_${field.id}`" v-model="field.label" 
                    @click.stop="$emit('fieldNameClick', field)" 
                    @blur="isEditable && updateFieldLabel(field)" 
                    :class="!field.label ? 'border border-dashed border-red-300 ' : ''" 
                    type="text" :disabled="!edited" 
                    class="section_name bg-transparent w-48 border-0 outline-none p-1 text-md text-base-content focus:bg-gray-200 rounded focus:border-none border-b border-dashed border-gray-300 truncate" />
                <span v-tippy :content="fetchLabel(field.label)" v-else class="section_name bg-transparent w-48 border-0 outline-none p-1 text-md text-base-content font-semibold truncate">{{ field.label }}</span>

                <div v-if="!['Candidate Consent', 'Candidate Optional Consent'].includes(field.type) && isEditable && this.$route.params.action !== 'view'" :disabled="this.$route.params.action === 'view'" class="h-8 w-8 flex items-center justify-center rounded-half hover:bg-primary-300 hover:text-white-text transition-all duration-150" :class="edited ? 'bg-primary-300 text-white-text' : 'text-primary'" @click.stop="onEdit(field)">
                    <font-awesome-icon icon="edit" class="cursor-pointer text-sm fill-current" />
                </div>
        
                <!-- <span>{{ field.name }}</span> -->
                <GapInfo v-bind:style="{ opacity: this.$route.params.action !== 'view' ? 1 : 0.5, cursor: this.$route.params.action !== 'view' ? 'pointer' : 'not-allowed' }" v-if="field.use_in_gap_validation" :element="field" @click.native.stop=" isEditable  && $emit('showGapValidationModal')" />
            </div>
            <div class="control-panel flex items-center gap-3">
                <div class="block_visible ml-8">
                <Checkbox
                    @click.native.stop
                    v-model="useblockvisible"
                    @input="changeblockVisibile(field)"
                    :disabled="this.$route.params.action === 'view' || !isEditable"
                    />
                </div>   
                <!-- <button @click.stop="$emit('handleShowPanel', { section, field })" type="button" class="px-4 py-1 font-light text-xs md:text-md text-white-text rounded-full bg-blue-500 border-2 border-white break-words hover:bg-blue-600">Configure Dependent Permissions</button> -->
                <FieldRolePanel v-if="isEditable" :field="field" :customRoles = "customRoles" @PermissionChange="PermissionChange" @change="change" @changeCaseCreation="changeCaseCreation" :allReportsRoles="allReportsRoles" />
            </div>
            <div class="flex items-center justify-center" v-if="isEditable && showVerticalGrip">
                <font-awesome-icon v-if="this.$route.params.action !== 'view'" class="cursor-pointer text-red-500 hover:text-red-700" @click.stop="$emit('removeFieldFromBlock', field)" icon="trash" v-bind:style="{ opacity: this.$route.params.action !== 'view' ? 1 : 0.5, cursor: this.$route.params.action !== 'view' ? 'pointer' : 'not-allowed' }"/>
            </div>
        </div>
    </div>
</div>    
</template>

<script>
import FieldRolePanel from "@/pages/check-admin/check-dragable-section/components/field-roles-panel";
// const CascadedIcon = () => import("@shared/assets/icons/field-cascaded.svg");
import { fetchLabel } from "@shared/utils/functions";
const GapInfo = () => import("@shared/components/GapInfo");
import Checkbox from "@/components/checkbox-alt";

export default {
    name: "fields",
    props: {
        field: {
            type: Object,
            default: () => {},
        },
        allReportsRoles: {
            type: Array,
            default: () => [],
        },
        fieldClass: {
            type: String,
            default: "",
        },
        updateFieldAttributesMethod: {
            type: Function,
        },
        updateFieldPermissions: {
            type: Function,
        },
        isEditable:{
            type:Boolean,
            default: true
        },
        showVerticalGrip:{
            type: Boolean,
            default:true
        },
        customRoles:{
            type: Array,
            default: () => [],
        }
    },
    components: {
        // CascadedIcon,
        GapInfo,
        FieldRolePanel,
        Checkbox,
    },
    data() {
        return {
            edited: false,
            counter:1
        };
    },
    computed: {
        fieldLabel() {
            return this.getFieldLabel(this.field);
        },
        useblockvisible: {
            get() {
                return this.field.block_visibility
            },
            set(val) {
                this.field.block_visibility = val;
            },
    },
    },
    methods: {
        fetchLabel,
        PermissionChange(role) {
            this.$emit("PermissionChange", role);
        },
        getFieldLabel(field) {
            return ["Candidate Consent", "Candidate Optional Consent"].includes(field.type) ? field.type.replace("Candidate", "") : field.label;
        },
        async change(event, isRole) {
            try {
                if (isRole) this.updateFieldPermissions(event, event.role, this.field.id, this.field);
                else this.updateFieldAttributesMethod(event, this.field.id);
            } catch (error) {
                if (isRole) {
                    let match = this.field.roles.find((rl) => event.role.role_id === rl.role_id);
                    Object.assign(match, event.backup);
                }
            }
            this.$forceUpdate();
        },
        changeCaseCreation({field}) {
            this.$emit("changeCaseCreation", field);
        },
        changeblockVisibile(field) {
            this.$emit('changeblockVisibile',field);
        },
        onEdit(fieldData) {
            if(this.$route.params.action === 'view'){
                return
            }
            if (!this.field?.label) {
                this.$toast.error("Label is required!");
                return;
            }
            this.edited = !this.edited;
            if (this.edited) {
                this.$emit('fieldNameClick', fieldData)
                this.$nextTick(() => {
                   this.$refs['input_' + fieldData.id].focus();
                });
            }
        },
        updateFieldLabel(field) {
            this.edited = !this.edited;
            this.$emit("updateFieldLabel", field);
        },
        
    },
};
</script>

<style lang="scss" scoped>
.check-field {
    display: grid;
    grid-template-columns: minmax(400px, auto) 1fr 50px;
    grid-auto-flow: column;
    .control-panel {
        margin-left: auto;
    }
    .block_visible{
        position: relative;
        left: -47px;
    }
}
.optionHeading{
color: #0236bb;
font-weight: 500;
}
.optionCircle{
    margin-top:5px;
  margin-left: -1%;
}
.ChangeCursor{
    cursor:default;
 }   
</style>
