<template>
    <div>
    <div class="flex items-center gap-3 w-full" :class="{'ChangeCursor':this.$route.params.action=='view'}">
        <font-awesome-icon v-if="showVerticalGrip" icon="grip-vertical" class="text-gray-500" />
        <div class="check-field bg-card-bg transition-all duration-200 px-2 py-1 flex justify-between items-center gap-3 rounded-xl cursor-pointer flex-grow">
            <div class="flex gap-3 items-center text-sm relative">
                <div class="cascadeOpen w-3 absolute" @click="cascadeClicked(element)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="40" viewBox="0 0 8 5" fill="none" :class="{'arrowUpward':openCascadedOption}">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.58021 0.703117C0.705077 0.578387 0.874411 0.508318 1.05097 0.508318C1.22754 0.508318 1.39687 0.578387 1.52174 0.703117L3.71442 2.89404L5.90711 0.703118C5.96853 0.639572 6.04201 0.588886 6.12325 0.554017C6.20448 0.519148 6.29186 0.500794 6.38027 0.500026C6.46869 0.499259 6.55637 0.516092 6.6382 0.549546C6.72003 0.582999 6.79437 0.632402 6.85689 0.694871C6.91941 0.75734 6.96886 0.831626 7.00234 0.913392C7.03582 0.995158 7.05266 1.08277 7.0519 1.17111C7.05113 1.25945 7.03276 1.34676 6.99786 1.42793C6.96296 1.5091 6.91224 1.58252 6.84864 1.64389L4.18519 4.3052C4.06032 4.42993 3.89099 4.5 3.71442 4.5C3.53786 4.5 3.36853 4.42993 3.24366 4.3052L0.580209 1.64389C0.455379 1.51912 0.385254 1.34992 0.385254 1.1735C0.385254 0.997082 0.45538 0.827884 0.58021 0.703117Z" fill="white"/>
                        </svg>
                </div>
                <input v-if="edited" :ref="`input_${element.id}`" v-model="element.label" @click.stop="$emit('fieldNameClick', element)" @blur="updateFieldLabel(element)" :class="!element.label ? 'border border-dashed border-red-300 ' : ''" type="text" class="section_name bg-transparent w-48 border-0 outline-none p-1 text-md base-content focus:bg-gray-200 rounded focus:border-none border-b border-dashed border-gray-300 truncate" />
                <span v-tippy :content="fieldLabel" v-else class="section_name bg-transparent w-48 border-0 ml-1 outline-none p-1 text-md base-content font-semibold truncate">{{ fieldLabel }}</span>
                <button v-if="!['Candidate Consent', 'Candidate Optional Consent'].includes(element.type) && isEditable && this.$route.params.action !== 'view'" :disabled="this.$route.params.action === 'view'" class="h-8 w-8 flex items-center justify-center rounded-half hover:bg-dvbrandhoveron hover:text-white-text transition-all duration-150" :class="edited ? 'bg-primary-300 text-white-text' : 'text-primary'" @click.stop="onEdit(element)">
                    <font-awesome-icon icon="edit" class="cursor-pointer text-sm fill-current" />
                </button>
                <!-- <span>{{ element.name }}</span> -->
                <GapInfo v-if="element.use_in_gap_validation" :element="element" />
                <!-- <button :disabled="!isEditable" class="disabled:opacity-50 cascadeBgColor p-1 rounded-xl" :class="[!isEditable && 'cursor-not-allowed']" @click.stop="$emit('showFieldSettingsModal', element)"> -->
                <button :disabled="!isEditable" class="disabled:opacity-50 cascadeBgColor p-1 rounded-xl" :class="[!isEditable && 'cursor-not-allowed']" @click="cascadeClicked(element)">
                    <!-- <CascadedIcon  class="w-5"  /> -->
                    Cascading Fields
                </button>
            </div>
            <div class="control-panel flex items-center gap-3">
                <div class="block_visible">
                <Checkbox
                    @click.native.stop
                    v-model="useblockvisible"
                    @input="changeblockVisibile(element)"
                    :disabled="this.$route.params.action == 'view' || !isEditable"
                    />
                </div>  
                <!-- <button @click.stop="$emit('handleShowPanel', { section, element })" type="button" class="px-4 py-1 font-light text-xs md:text-md text-white-text rounded-full bg-blue-500 border-2 border-white break-words hover:bg-blue-600">Configure Dependent Permissions</button> -->
                <FieldRolePanel v-allow="'block.edit'" :field="element" @PermissionChange="PermissionChange" @change="change" @changeCaseCreation="changeCaseCreation" :allReportsRoles="allReportsRoles" :openCascadedOption="openCascadedOption" />
            </div>
            <div class="flex items-center justify-center" v-if="isEditable && showVerticalGrip" >
                <font-awesome-icon v-if="this.$route.params.action !== 'view'" class="cursor-pointer text-red-500 hover:text-red-700" @click.stop="$emit('removeFieldFromBlock', element)" icon="trash" v-bind:style="{ opacity: this.$route.params.action !== 'view' ? 1 : 0.5, cursor: this.$route.params.action !== 'view' ? 'pointer' : 'not-allowed' }" />
            </div>
        </div>
    </div>  
    <div v-if="openCascadedOption">
            <BlockAdminDetails v-if="openCascadedOption" :openCascadedOption="openCascadedOption" :blockDetailsData = "blockDetails" name="BlockDisplay"/>    
    </div> 
</div>
</template>

<script>
import FieldRolePanel from "@/pages/check-admin/check-dragable-section/components/field-roles-panel";
// const CascadedIcon = () => import("@shared/assets/icons/field-cascaded.svg");
const GapInfo = () => import("@shared/components/GapInfo");
import { checkPermission } from "@shared/utils/functions";
import Checkbox from "@/components/checkbox-alt";
import axios from "@/axios";
// import BlockDisplay from "./blockDisplay.vue";  
export default {
    name: "fields",
    props: {
        element: {
            type: Object,
            default: () => {},
        },
        allReportsRoles: {
            type: Array,
            default: () => [],
        },
        fieldClass: {
            type: String,
            default: "",
        },
        updateFieldAttributesMethod: {
            type: Function,
        },
        updateFieldPermissions: {
            type: Function,
        },
        isEditable: {
            type: Boolean,
            default: true,
        },
        showVerticalGrip:{
            type: Boolean,
            default:true
        },
    },
    components: {
        // CascadedIcon,
        GapInfo,
        FieldRolePanel,
        Checkbox,
        // BlockDisplay
        // BlockDisplay: () => import('./blockDisplay.vue'),
        BlockAdminDetails: () => import('.././block-admin-details/index.vue'),
    },
    data() {
        return {
            edited: false,
            optionalDependentFields:[],
            blockDetails:{},
            openCascadedOption: false,
            cascade :{
                tenatId:null,
                Id:null
            }
        };
    },
    computed: {
        fieldLabel() {
            return this.getFieldLabel(this.element);
        },
        useblockvisible: {
            get() {
                return this.element.block_visibility
            },
            set(val) {
                this.element.block_visibility = val;
            },
        }
    },
    methods: {
        checkPermission,
        PermissionChange(role) {
            this.$emit("PermissionChange", role);
        },
        getFieldLabel(field) {
            return ["Candidate Consent", "Candidate Optional Consent"].includes(field.type) ? field.type.replace("Candidate", "") : field.label;
        },

        async change(event, isRole) {
            try {
               if (isRole) this.updateFieldPermissions(event, event.role, this.element.id, this.element);
                else this.updateFieldAttributesMethod(event, this.element.id);
                this.AgaincascadeClicked();
            } catch (error) {
                if (isRole) {
                    let match = this.element.roles.find((rl) => event.role.role_id === rl.role_id);
                    Object.assign(match, event.backup);
                }
            }
        },
        changeCaseCreation({ field }) {
            this.$emit("changeCaseCreation", field);
        },
        changeblockVisibile(element) {
            this.$emit('changeblockVisibile', element)
        },
        onEdit(fieldData) {
            if (!this.element?.label) {
                this.$toast.error("Label is required!");
                return;
            }
            this.edited = !this.edited;
            if (this.edited) {
                this.$emit("fieldNameClick", fieldData);
                this.$nextTick(() => {
                    this.$refs["input_" + fieldData.id].focus();
                });
            }
        },
        updateFieldLabel(field) {
            this.edited = !this.edited;
            this.$emit("updateFieldLabel", field);
        },
        async AgaincascadeClicked(){
        
            let { data } = await axios.get(`/blocks/${this.cascade.tenantId}/field/${this.cascade.Id}/options`);
            this.cascadeDependentsClicked(data,{
                tenant_block_id:this.cascade.tenantId,
                id:this.cascade.Id
            });
        },
       async cascadeClicked(e){
        this.cascade.tenantId=e.tenant_block_id || this.$route.params.id;
        let inPageUrl_id = this.$route.params.id || e.tenant_block_id
        if (inPageUrl_id){
            this.cascade.Id=e.id;
            let { data } = await axios.get(`/blocks/${inPageUrl_id}/field/${e.id}/options`);
            this.cascadeDependents(data,e);
        }
        },
        async cascadeDependents(data,e){
            if(this.openCascadedOption == false){
                let options = [];
            for(let x of data.data){
                this.optionalDependentFields.push({optionHere:true,
                            optionLabel:x.option_label,
                            id:x.id,
                            entity_type:'field'})
               if(x.is_dependent === true){
                let inPageUrl_id = this.$route.params.id || e.tenant_block_id
                let optionData = await axios.get(`/blocks/${inPageUrl_id}/field/${e.id}/options/${x.option_value}`);
                options = [...optionData.data.dependents];
                this.optionalDependentFields.push(...options);
               }
            }
            this.blockDetails.fields = this.optionalDependentFields;
            this.blockDetails.id = this.$route.params.id ;
            this.openCascadedOption = true ;
            }
            else
            {   this.optionalDependentFields = [];
                this.openCascadedOption = false ;
                console.log("this.openCascadedOptionfalse",this.openCascadedOption)
                return ;
            }
        },
        async cascadeDependentsClicked(data,e){
            if(this.openCascadedOption == false){
                let options = [];
            for(let x of data.data){
               if(x.is_dependent === true){
                let option_value = JSON.parse(JSON.stringify(x.option_value))
                let optionData = await axios.get(`/blocks/${e.tenant_block_id}/field/${e.id }/options/${option_value}`);
                options = [...optionData.data.dependents];
                this.optionalDependentFields.push(...options);
               }
            }
            this.blockDetails.fields = this.optionalDependentFields;
            this.blockDetails.id = this.$route.params.id ;
            }
            else
            {   this.optionalDependentFields = [];
                return ;
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.check-field {
    display: grid;
    grid-template-columns: minmax(400px, auto) 1fr 50px;
    grid-auto-flow: column;
    .control-panel {
        margin-left: auto;
    }
    .block_visible{
        position: relative;
        left: -47px;
    }
}
.cascadeBgColor{
                background-color: #a1cdfb;
            }
.cascadeOpen{
    background-color: #0d6ad5;
    left: -9px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    justify-content: center;
}  
.arrowUpward{
    transform: rotate(180deg);
  transform-origin: 50% 50%;
 }   
 .ChangeCursor{
    cursor:default;
 }     
</style>
